<template>
    <BaseSlider 
        v-if="isMobile || items.length > 3"
        class="research-cards research-cards--slider"
        :items="items"
        :track-intersection="true"
        :intersection-threshold=".75"
    >
        <template v-slot:slide="{ item, isVisible }">
            <ResearchCard
                :class="isVisible ? '' : 'research-card--faded'"
                :item="item"
            />
        </template>
    </BaseSlider>
    <div 
        v-else 
        class="research-cards research-cards--grid"
    >
        <ResearchCard
            v-for="(item, key) in items"
            :key="key"
            :item="item"
        />
    </div>
</template>

<script setup>
import { useMediaQuery } from '@vueuse/core';

defineProps({
    items: {
        type: Array,
        required: true,
        default: () => { return []; }
    }
});

const isMobile = useMediaQuery('(max-width: 576px)');
</script>


<style src="./ResearchCards.less" lang="less" scoped />
