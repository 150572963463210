<template>
    <NuxtLink
        v-if="item && header"
        :to="getLink(item)"
        class="research-card background--mint"
    >
        <div class="research-card__text">
            <p
                v-if="category"
                class="research-card__tag"
            >
                {{ category }}
            </p>
            <p
                v-if="header"
                class="research-card__header"
            >
                {{ header }}
            </p>
        </div>
        <AuthorChip
            v-if="allAuthors?.length < 2 && !isEmpty(allAuthors?.[0]) "
            class="research-card__author"
            :name="allAuthors?.[0]?.title"
            :role="allAuthors?.[0]?.role"
            :image="allAuthors?.[0]?.image?.[0]"
        />
        <MultipleAuthors
            v-else-if="allAuthors?.length > 1"
            class="research-card__author"
            :authors="allAuthors"
        />
    </NuxtLink>
</template>

<script setup>
import { isEmpty } from 'lodash-es';

const props = defineProps({
    item: {
        type: Object,
        required: true,
        default: () => { return {}; }
    }
});

const { item } = toRefs(props);

const header = computed(() => item.value?.title ?? '');
const authors = computed(() => item.value?.authorRelation ?? []);

const allAuthors = computed(() => {
    const arr = authors.value;
    
    if (item.value?.authorName) {
        arr.push({
            title: item?.value?.authorName ?? null,
            role: item?.value?.authorRole ?? null,
            uri: item?.value?.authorLink?.[0]?.page?.[0]?.uri ?? null,
            image: item?.value?.authorImage ?? null,
            externalUrl: item?.value?.authorLink?.[0]?.linkUrl ?? null,
        });
    }

    return arr;
});

const category = computed(() => {
    if (item.value?.generalCategory) {
        return item.value?.generalCategory;
    }
    return item.value?.blogCategory?.[0]?.title ?? '';
});
</script>
<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ]
};
</script>

<style lang="less" src="./ResearchCard.less"></style>
